import React from 'react'

export default function InformationAbout() {
  return (
        
        <>
            <section className="information-section">
                <div className="container">
                    <div className="information-item">
                        <div className="row">
                            <div className="col col-12 col-md-7 col-lg-7 information-img-col">
                                <div className="information-img">
                                    <div className="why-barreras-img" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src="assets/images/information.png" alt="information" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-11 col-md-6 col-lg-6 information-content-col">
                                <div className="information-content">
                                    <h3 className="information-content-title" data-aos="fade-up" data-aos-duration="2000">INFORMATION</h3>
                                    <p className="information-content-description" data-aos="fade-up" data-aos-duration="2500">Detailed information about the purchasing, selling or renting of your house, office or shop.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="information-item">
                        <div className="row">
                            <div className="col col-11 col-md-6 col-lg-6 information-content-col">
                                <div className="information-content">
                                    <h3 className="information-content-title" data-aos="fade-up" data-aos-duration="2000">CONSULTING</h3>
                                    <p className="information-content-description" data-aos="fade-up" data-aos-duration="2500">Clarification of doubts and legal or fiscal assessment.</p>
                                </div>
                            </div>
                            <div className="col col-12 col-md-7 col-lg-7 information-img-col">
                                <div className="information-img">
                                    <div className="why-barreras-img" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src="assets/images/consulting.png" alt="consulting" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="information-item">
                        <div className="row">
                            <div className="col col-12 col-md-7 col-lg-7 information-img-col">
                                <div className="information-img">
                                    <div className="why-barreras-img" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src="assets/images/consulting.png" alt="consulting" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-11 col-md-6 col-lg-6 information-content-col">
                                <div className="information-content">
                                    <h3 className="information-content-title" data-aos="fade-up" data-aos-duration="2000">INVESTMENT</h3>
                                    <p className="information-content-description" data-aos="fade-up" data-aos-duration="2500">Ideas to invest in properties in Tenerife and counseling to individuals buying their main house.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="information-item">
                        <div className="row">
                            <div className="col col-11 col-md-6 col-lg-6 information-content-col">
                                <div className="information-content">
                                    <h3 className="information-content-title" data-aos="fade-up" data-aos-duration="2000">ATTENTION</h3>
                                    <p className="information-content-description" data-aos="fade-up" data-aos-duration="2500">Careful personalized attention to recurring clients, national investors, or from other countries.</p>
                                </div>
                            </div>
                            <div className="col col-12 col-md-7 col-lg-7 information-img-col">
                                <div className="information-img">
                                    <div className="why-barreras-img" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src="assets/images/consulting.png" alt="consulting" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="information-item">
                        <div className="row">
                            <div className="col col-12 col-md-7 col-lg-7 information-img-col">
                                <div className="information-img">
                                    <div className="why-barreras-img" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src="assets/images/consulting.png" alt="consulting" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-11 col-md-6 col-lg-6 information-content-col">
                                <div className="information-content">
                                    <h3 className="information-content-title" data-aos="fade-up" data-aos-duration="2000">QUALITY</h3>
                                    <p className="information-content-description" data-aos="fade-up" data-aos-duration="2500">Dynamism and quality of service in the commitment of not dissapointing your trust.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
