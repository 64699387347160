import React from 'react'
import LandingSection from '../buy_sell/LandingSection'
import ContactUsForm from '../contact_us/ContactUsForm'
import InquerySectionMap from '../contact_us/InquerySectionMap'
import PropertyDetailsSlider from './PropertyDetailsSlider'
import PropertyTagDescription from './PropertyTag'
import RelatedProperty from './RelatedProperty'
export default function PropertyDetailsPage() {
  return (
    <>
      
      <LandingSection landingTitle="425 Florence Cath St"></LandingSection>
      <PropertyDetailsSlider></PropertyDetailsSlider>
      <section className="property-detail-section">
         <PropertyTagDescription></PropertyTagDescription>
         <div className="container"> 
         <h3 className="property-description-title" data-aos="zoom-in" data-aos-duration="2000">Contact us</h3>
         <ContactUsForm></ContactUsForm>
         </div>
         <InquerySectionMap></InquerySectionMap>
         <RelatedProperty></RelatedProperty>
      </section>
    

    </>
  )
}
