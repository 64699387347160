import React from 'react'

export default function ServicesDescription() {
  return (
            
            <>
            <section className="why-barreras-section about-us-section">
                    <div className="container">
                        <div className="about-us-section-item">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="why-barreras-content">
                                        <h3 className="why-barreras-about-us-title" data-aos="zoom-in" data-aos-duration="2000">Gestionamos tu compra</h3>
                                        <p className="why-barreras-content-discription" data-aos="fade-up" data-aos-duration="2000">Las gestiones que debes realizar para la compra de tu nueva casa te sobrecargan? Las gestiones que hacemos de forma gratuita para que evites mayor estrés:</p>
                                        <ol className="why-barreras-content-discription" data-aos="fade-up" data-aos-duration="2500">
                                            <li>Formalización del N.I.E. (si hace falta)</li>
                                            <li> Solicitud de toda la documentación necesaria referente a la propiedad elegida para estudio profundizado de la situación económica y catastral.</li>
                                            <li>Garantizar la venta del inmueble libre de deudas, embargos, inquilinos o cualquier otra obligación frente a terceros</li>
                                            <li>Asesoramiento de apertura de cuenta bancaria</li>
                                            <li> Formalización notarial de compra-venta de acuerdo con la actual legislación española</li>
                                            <li> Formalización de la hipoteca para la compra o construcción de la vivienda</li>
                                            <li>Servicios de traducción</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="why-barreras-img" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src="assets/images/gestionamos-compra.png" alt="gestionamos-compra" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-us-section-item">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="why-barreras-img" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src="assets/images/nuestra-img.png" alt="nuestra-img" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="why-barreras-content">
                                        <h3 className="why-barreras-about-us-title" data-aos="zoom-in" data-aos-duration="2000">Nuestra asistencia tras la compra-venta</h3>
                                        <p className="why-barreras-content-discription" data-aos="fade-up" data-aos-duration="2000">¿La realización de los pagos y los trámites post compra-venta requieren mucho tiempo y eso a ti te gustaría ahorrarlo? Confía en nosotros y lo realizamos rápidamente por un precio razonable. A continuación, te indicamos los
                                            trámites que hay que realizar tras la compra-venta:</p>
                                        <ol className="why-barreras-content-discription" data-aos="fade-up" data-aos-duration="2500">
                                            <li> Realización del pago de los impuestos: IGIC (Impuesto General Islas Canarias) o ITP (Impuesto Transmisiones Patrimoniales)</li>
                                            <li>Alta en todas las tasas municipales y de servicios, IBI (impuesto sobre bienes inmuebles), basura, alcantarillado, agua y luz </li>
                                            <li>Presentación y pago en el Registro de la Propiedad</li>
                                            <li>Otras consultas jurídicas y servicios relacionados con asuntos inmobiliarios</li>
                                            <li>Alquiler a corto y a largo plazo de villas, pisos, apartamentos y etc.</li>
                                            <li>Realización de proyectos en inversion</li>
                                            <li>Servicios notariales y de traducción en asuntos relacionados con bienes inmuebles</li>
                                            <li>Reformas en general</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-us-section-item">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="why-barreras-content">
                                        <h3 className="why-barreras-about-us-title" data-aos="zoom-in" data-aos-duration="2000">Seguro Caser</h3>
                                        <p className="why-barreras-content-discription" data-aos="fade-up" data-aos-duration="2000">Información Corporativa Caser Seguros <br/><br/> Caser es un grupo asegurador que nació hace más de 75 años. Hoy, nuestro propósito es el mismo que entonces: ofrecer un producto de valor y un excelente servicio. <br/><br/>                                En 1942 comenzamos dando cobertura a la Asociación de Agricultores. Poco a poco, fuimos incorporando productos para particulares y empresas, y crecimos hasta convertirnos en un grupo asegurador multigrano y especialista
                                            en servicio. Desde 2003 operamos en otros mercados que comparten sinergias con el asegurador: residencias para personas mayores, hospitales o facility management.<br/><br/> Procuramos conocer en detalle las necesidades
                                            de los clientes y anticiparnos con seguros y servicios innovadores. </p>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="why-barreras-img" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src="assets/images/caser-img.png" alt="caser-img" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-us-section-item">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="why-barreras-img" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src="assets/images/tyco-img.png" alt="tyco-img" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="why-barreras-content">
                                        <h3 className="why-barreras-about-us-title" data-aos="zoom-in" data-aos-duration="2000">Alarma TYCO</h3>
                                        <p className="why-barreras-content-discription" data-aos="fade-up" data-aos-duration="2000">Las alarmas de Tyco, ahora conocidas como ADT Alarmas, son las que mejor calidad-precio ofrecen entre las grandes empresas de seguridad españolas e internacionales <br/><br/>Te explicamos la clave de sus alarmas y todos los
                                            elementos que incorporan. <br/><br/>En el mes de enero de 2020 la empresa de seguridad Tyco, propiedad de Johnson Controls ha pasado a llamarse ADT. El cambio de nombre ha traído grandes innovaciones a sus sistemas de seguridad
                                            y nuevos packs de alarmas. <br/><br/>¡Te lo contamos!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-us-section-item">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="why-barreras-content">
                                        <h3 className="why-barreras-about-us-title" data-aos="zoom-in" data-aos-duration="2000">Energética Iberdrola</h3>
                                        <p className="why-barreras-content-discription" data-aos="fade-up" data-aos-duration="2000">Iberdrola, S. A. es una empresa española que tiene la sede en Bilbao, en el País Vasco, España. Es un grupo empresarial dedicado a la producción, distribución y comercialización de energía. Su nombre es el resultado de la fusión
                                            a finales de los 80 de Iberduero con Hidrola, dos empresas eléctricas que a su vez eran fusiones de otras anteriores <br/><br/> Iberdrola constituye una de las grandes empresas eléctricas a nivel internacional, es el segundo
                                            grupo de producción eléctrica en España, el primer grupo energético de España por capitalización bursátil y cuarto del mundo y se sitúa a la cabeza del sector eólico mundial.<br/><br/> Iberdrola es la séptima empresa que
                                            emite más toneladas de CO2 equivalente en España. En el año 2018 Iberdrola se comprometió a reducir las emisiones de CO2 en un 50% para 2030 y a finales de 2017 anunció su intención de cerrar todas las centrales térmicas
                                            de carbón que tenía en el mundo.</p>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="why-barreras-img" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src="assets/images/Iberdrola-img.png" alt="Iberdrola-img" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
            </>
  )
}
