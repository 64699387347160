import React from 'react'
import ContactUsForm from './ContactUsForm'

export default function GetInTouchSection() {
  return (
   <>
     {/* <!-- Get In Touch Section Start --> */}
        <section className="get-touch-section">
            <div className="container">
                <div className="get-touch-heading">
                    <h2 className="text-center section-main-title" data-aos="zoom-in" data-aos-duration="1500">Get In Touch</h2>
                    <p className="section-description" data-aos="zoom-in" data-aos-duration="2000">Any questions? We are here to help with anything you need.</p>
                </div>
             <ContactUsForm></ContactUsForm>
            </div>
        </section>
    {/* <!-- Get In Touch Section End --> */}
   </>
  )
}
