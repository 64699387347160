import React,{ useEffect} from 'react'
import LandingSection from '../buy_sell/LandingSection'
import Aos from 'aos';
import WhyBarreras from './WhyBarreras';
import RentPropertyAbout from './RentPropertyAbout';
import setting from '../../general.js';
import Team from './Team';
import AboutUsFormate from './AboutUsFormate';
import InformationAbout from './InformationAbout';
export default function About() {
    useEffect( () => {
        setTimeout(() => {
          Aos.init({
            once : true
          });
        }, 2000);
        Aos.refresh();
      });
  return (
       <>
        <LandingSection landingTitle="About Us" background="about-landing-section"></LandingSection>
        <WhyBarreras></WhyBarreras>
        <section className="about-banner" data-aos="zoom-in" data-aos-duration="2000">
            <img src="assets/images/about-banner.png" alt="about-banner" className="img-fluid w-100"/>
        </section>
        <RentPropertyAbout setting={setting()}></RentPropertyAbout>
        <Team></Team>
       <AboutUsFormate></AboutUsFormate>
       <InformationAbout></InformationAbout>
       </>
  
    )
}

