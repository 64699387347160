import React from 'react'

export default function LandingSection(props) {
  return (
    <>
        <section className={`properties-section ${props.background}`} data-aos="fade-in" data-aos-duration="2000">
            <div className="container">
                <h1 className="properties-title section-title text-center text-white" data-aos="fade-up" data-aos-duration="2000">{props.landingTitle}</h1>
            </div>
        </section> 
    </>
  )
}

LandingSection.defaultProps = {
  landingTitle: "Page Title"
}