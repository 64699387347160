import React , { useEffect} from 'react'
import Aos from 'aos';
import Slider from 'react-slick';
export default function PropertyDetailsSlider() {

    useEffect( () => {
        setTimeout(() => {
          Aos.init({
            once : true
          });
        }, 2000);
        Aos.refresh();
      });

 const Propertys = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        dots: true,
        speed: 1000,
        // autoplay: true,
        autoplaySpeed: 2000,
        responsive: [{
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
           },
        ]
}

  return (
    <>
        <section className="rent-slider-section property-detail-slider-section" data-aos="zoom-in" data-aos-duration="2000">
            <div className="container ">
                <div className="rent-slider-content ">
                    <div className="property-detail-slider-list">
                        <Slider {...Propertys}>
                        <div className="rent-slider-item ">
                            <div className=" rent-slider-img ">
                                <img src="assets/images/rent_3.png" alt="" />
                                <div className="rent-slider-img-two shadow">
                                    <img src="assets/images/rent_4.png" alt="" />
                                </div>
                                <div className="rent-slider-details bg-white ">
                                    <h2 className="rent-slider-details-title section-title">425 Florence Cath St</h2>
                                    <p className="rent-slider-details-discription section-discription">Buying a home is a big step and there can be a lot to consider. At Inmobiliaria Barreras we believe it should be one of the most fulfilling experiences of your life.</p>
                                    <p className="florence-location section-discription"><i className="bi bi-geo-alt"></i>Location</p>
                                    <div className="florence-bedroom">
                                        <span className="florence-home-item"><img src="assets/images/bed.png" alt="" className="img-fluid" /> 2 Bedroom</span>
                                        <span className="florence-home-item"><img src="assets/images/bath.png" alt="" className="img-fluid" /> 2 Bathroom</span>
                                        <span className="florence-home-item"><img src="assets/images/area.png" alt="" className="img-fluid" /> 130 Area M2</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rent-slider-item ">

                            <div className=" rent-slider-img ">
                                <img src="assets/images/rent_3.png " alt=" " />
                                <div className="rent-slider-img-two shadow">
                                    <img src="assets/images/rent_4.png " alt=" " />
                                </div>
                                <div className="rent-slider-details bg-white ">
                                    <h2 className="rent-slider-details-title section-title">RENT</h2>
                                    <p className="rent-slider-details-discription section-discription">Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis minima dignissimos iusto doloribus est aliquid eaque officia tempore, ipsum dolorem, fuga in provident consectetur dicta nam nobis. Repellendus, est vitae?</p>
                                    <a href="/" className="btn btn-dark ">View</a>
                                </div>
                            </div>
                        </div>
                        <div className="rent-slider-item ">

                            <div className=" rent-slider-img ">
                                <img src="assets/images/rent_3.png " alt=" " />
                                <div className="rent-slider-img-two shadow">
                                    <img src="assets/images/rent_4.png " alt=" " />
                                </div>
                                <div className="rent-slider-details bg-white ">
                                    <h2 className="rent-slider-details-title section-title">RENT</h2>
                                    <p className="rent-slider-details-discription section-discription">Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis minima dignissimos iusto doloribus est aliquid eaque officia tempore, ipsum dolorem, fuga in provident consectetur dicta nam nobis. Repellendus, est vitae?</p>
                                    <a href="/" className="btn btn-dark ">View</a>
                                </div>
                            </div>
                        </div>
                        <div className="rent-slider-item ">

                            <div className=" rent-slider-img ">
                                <img src="assets/images/rent_3.png " alt=" " />
                                <div className="rent-slider-img-two shadow">
                                    <img src="assets/images/rent_4.png " alt=" " />
                                </div>
                                <div className="rent-slider-details bg-white ">
                                    <h2 className="rent-slider-details-title section-title">RENT</h2>
                                    <p className="rent-slider-details-discription section-discription">Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis minima dignissimos iusto doloribus est aliquid eaque officia tempore, ipsum dolorem, fuga in provident consectetur dicta nam nobis. Repellendus, est vitae?</p>
                                    <a href="/" className="btn btn-dark ">View</a>
                                </div>
                            </div>
                        </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </section> 
    </>
  )
}
