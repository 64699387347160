import React, { useEffect } from 'react'
import InquerySection from './InquerySection'
import GetInTouchSection from './GetInTouchSection'
import InquerySectionMap from './InquerySectionMap'
import LandingSection from '../buy_sell/LandingSection'
import Aos from 'aos'

export default function Contact_us() {

  useEffect( () => {
    setTimeout(() => {
      Aos.init({
        once : true
      });
    }, 2000);
    Aos.refresh();
  });

 return (
   <>
   <LandingSection landingTitle="Contact Us" background="contact-landing-section"></LandingSection>
    <GetInTouchSection></GetInTouchSection>
    <InquerySection></InquerySection>
    <InquerySectionMap></InquerySectionMap>
   </>
  )
}
