import React from 'react'

export default function RelatedProperty() {
  return (

        <>
            <div className="container">
                <div className="related-properties-content">
                    <h2 className="property-description-title" data-aos="zoom-in" data-aos-duration="2000">Related properties</h2>
                    <div className="row">
                        <div className="col col-12 col-md-6 col-lg-4">
                            <div className="related-properties-card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="related-properties-img">
                                    <img src="assets/images/related-properties.png" alt="related-properties" className="img-fluid" />
                                </div>
                                <div className="related-properties-body">
                                    <h3 className="related-properties-title">425 Florence Cath St</h3>
                                    <p className="florence-location section-discription"><i className="bi bi-geo-alt"></i>Location</p>
                                    <div className="florence-bedroom">
                                        <span className="florence-home-item"><img src="assets/images/bed.png" alt="" className="img-fluid" /> 2 Bedroom</span>
                                        <span className="florence-home-item"><img src="assets/images/bath.png" alt="" className="img-fluid" /> 2 Bathroom</span>
                                        <span className="florence-home-item"><img src="assets/images/area.png" alt="" className="img-fluid" /> 130 Area M2</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-6 col-lg-4">
                            <div className="related-properties-card" data-aos="fade-up" data-aos-duration="2000">
                                <div className="related-properties-img">
                                    <img src="assets/images/related-properties1.png" alt="related-properties" className="img-fluid" />
                                </div>
                                <div className="related-properties-body">
                                    <h3 className="related-properties-title">425 Florence Cath St</h3>
                                    <p className="florence-location section-discription"><i className="bi bi-geo-alt"></i>Location</p>
                                    <div className="florence-bedroom">
                                        <span className="florence-home-item"><img src="assets/images/bed.png" alt="" className="img-fluid" /> 2 Bedroom</span>
                                        <span className="florence-home-item"><img src="assets/images/bath.png" alt="" className="img-fluid" /> 2 Bathroom</span>
                                        <span className="florence-home-item"><img src="assets/images/area.png" alt="" className="img-fluid" /> 130 Area M2</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-6 col-lg-4">
                            <div className="related-properties-card" data-aos="fade-up" data-aos-duration="2500">
                                <div className="related-properties-img">
                                    <img src="assets/images/related-properties2.png" alt="related-properties" className="img-fluid" />
                                </div>
                                <div className="related-properties-body">
                                    <h3 className="related-properties-title">425 Florence Cath St</h3>
                                    <p className="florence-location section-discription"><i className="bi bi-geo-alt"></i>Location</p>
                                    <div className="florence-bedroom">
                                        <span className="florence-home-item"><img src="assets/images/bed.png" alt="" className="img-fluid"/> 2 Bedroom</span>
                                        <span className="florence-home-item"><img src="assets/images/bath.png" alt="" className="img-fluid"/> 2 Bathroom</span>
                                        <span className="florence-home-item"><img src="assets/images/area.png" alt="" className="img-fluid"/> 130 Area M2</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}
