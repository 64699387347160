import React from 'react'

export default function InquerySection() {
  return (
  <>
    {/* // <!-- Inquery Section Start --> */}
    <section className="inquiries-section">
        <div className="container">
            <div className="row align-items-center">
                <div className="col col-12 col-md-6 col-lg-6">
                    <div className="inquiries-section-title" data-aos="zoom-in" data-aos-duration="1000">
                        <h2 className="section-main-title">Sales Inquiries<br/> & Information</h2>
                    </div>
                </div>
                <div className="col col-12 col-md-6 col-lg-6">
                    <div className="inquiries-section-content">
                        <div className="inquiries-content-item" data-aos="fade-up" data-aos-duration="1000">
                            <label className="inquiries-label">Email:</label>
                            <a className="inquiries-link" href="mailto:info@barrerastenerife.com">info@barrerastenerife.com</a>
                        </div>
                        <div className="inquiries-content-item" data-aos="fade-up" data-aos-duration="1500">
                            <label className="inquiries-label">Phone:</label>
                            <a className="inquiries-link" href="tel:+34822298128">(+34) 822 29 81 28</a>
                            <a className="inquiries-link" href="tel:+34638418917">(+34) 638 41 89 17</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Inquery Section End --> */}
    </>
  )
}
