import { Routes, Route } from 'react-router-dom';
import { Navigate  } from 'react-router-dom';
import HomePage from './components/home/HomePage';
import BuySellPage from './components/buy_sell/BuySellPage';
import PropertyDetailsPage from './components/Property_Details/PropertyDetailsPage';
import Header from './components/header';
import Footer from './components/footer';
import Contact_us from './components/contact_us/ContactUs';
import About from './components/about/about';
import Services from './components/services/services';

function App() {

  return (
   <>
      <Header></Header>
      <Routes>
      <Route path='/' element={ <Navigate to="/Home"/> }/>
        <Route exact path="/Home" element={<HomePage/>} />
        <Route exact path="/buy" element={<BuySellPage/>} />
        <Route exact path="/property-details" element={<PropertyDetailsPage />} />
        <Route exact path="/contact_us" element={<Contact_us/>} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/services" element={<Services />} />

        <Route exact path="*" element={<Navigate to="/Home"/>} />
      </Routes> 
      <Footer></Footer>
   </>
  );
}

export default App;
