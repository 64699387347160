import React, {useEffect} from 'react'
import Slider from "react-slick"


export default function Testimonials(props) {
    
  
    // useEffect( () => {
    //     Aos.init({
    //       once : true
    //     });
    //   });

  return (
    <>
        <section className="lorenzo-section">
            <div className="container">
                <div className="lorenzo-content">
                    <h2 className="rent-slider-title text-center section-main-title lorenzo-title" data-aos="fade-up" data-aos-duration="1000">Customer Testimonials
                    </h2>
                    <h4 className="lorenzo-subtitle text-center section-discription"  data-aos="fade-up" data-aos-duration="1500">What our customers say about us.
                    </h4>
                    {/* <div className="rent-slider-list  data-aos="zoom-in" data-aos-duration="2000"> */}

                    <Slider {...props.setting} >
                        <div className="rent-slider-item lorenzo-item" data-aos="zoom-in" data-aos-duration="2000">
                            <div className=" rent-slider-img  lorenzo-img">
                                <img src="assets/images/gara.png " alt=" " className="img-fluid" />
                                <div className="rent-slider-details bg-white lorenzo-details">
                                    <h2 className="rent-slider-details-title lorenzo-details-title section-title">Gara Lorenzo Martin
                                    </h2>
                                    <p className="rent-slider-details-discription section-discription mb-0 lorenzo-discription">
                                        Muy buena atención y profesionalidad por parte de Marina. Nos ha guiado y nos ha ayudado en todo el proceso de compraventa de la vivienda. Ahora somos propietarios y aún estamos en contacto. Recomendados 100%.</p>
                                </div>
                            </div>
                        </div>
                        <div className="rent-slider-item lorenzo-item" data-aos="zoom-in" data-aos-duration="2000">
                            <div className=" rent-slider-img  lorenzo-img">
                                <img src="assets/images/gara.png " alt=" " className="img-fluid" />
                                <div className="rent-slider-details bg-white lorenzo-details">
                                    <h2 className="rent-slider-details-title lorenzo-details-title section-title">Gara Lorenzo Martin
                                    </h2>
                                    <p className="rent-slider-details-discription section-discription mb-0 lorenzo-discription">
                                        Muy buena atención y profesionalidad por parte de Marina. Nos ha guiado y nos ha ayudado en todo el proceso de compraventa de la vivienda. Ahora somos propietarios y aún estamos en contacto. Recomendados 100%.</p>
                                </div>
                            </div>
                        </div>
                        <div className="rent-slider-item lorenzo-item" data-aos="zoom-in" data-aos-duration="2000">
                            <div className=" rent-slider-img  lorenzo-img">
                                <img src="assets/images/gara.png " alt=" " className="img-fluid" />
                                <div className="rent-slider-details bg-white lorenzo-details">
                                    <h2 className="rent-slider-details-title lorenzo-details-title section-title">Gara Lorenzo Martin
                                    </h2>
                                    <p className="rent-slider-details-discription section-discription mb-0 lorenzo-discription">
                                        Muy buena atención y profesionalidad por parte de Marina. Nos ha guiado y nos ha ayudado en todo el proceso de compraventa de la vivienda. Ahora somos propietarios y aún estamos en contacto. Recomendados 100%.</p>
                                </div>
                            </div>
                        </div>
                        <div className="rent-slider-item lorenzo-item" data-aos="zoom-in" data-aos-duration="2000">
                            <div className=" rent-slider-img  lorenzo-img">
                                <img src="assets/images/gara.png " alt=" " className="img-fluid" />
                                <div className="rent-slider-details bg-white lorenzo-details">
                                    <h2 className="rent-slider-details-title lorenzo-details-title section-title">Gara Lorenzo Martin
                                    </h2>
                                    <p className="rent-slider-details-discription section-discription mb-0 lorenzo-discription">
                                        Muy buena atención y profesionalidad por parte de Marina. Nos ha guiado y nos ha ayudado en todo el proceso de compraventa de la vivienda. Ahora somos propietarios y aún estamos en contacto. Recomendados 100%.</p>
                                </div>
                            </div>
                        </div>
                     </Slider>
                        

                    {/* </div> */}
                </div>
            </div>
        </section>
    </>
  )
}
