import React from 'react'

export default function PropertyTagDescription() {
  return (
    <>
        {/* <!-- Property Details Section Start --> */}
                <div className="container">
                    <div className="property-tags">
                        <h3 className="property-tags-title" data-aos="zoom-in" data-aos-duration="2000">Tags</h3>
                        <ul className="property-tags-list" data-aos="fade-up" data-aos-duration="2000">
                            <li className="property-tags-item">
                                <a href="#" className="property-tags-link">adeje</a>
                            </li>
                            <li className="property-tags-item">
                                <a href="#" className="property-tags-link">obra nueva</a>
                            </li>
                            <li className="property-tags-item">
                                <a href="#" className="property-tags-link">plaza garaje incluida</a>
                            </li>
                            <li className="property-tags-item">
                                <a href="#" className="property-tags-link">terraza</a>
                            </li>
                            <li className="property-tags-item">
                                <a href="#" className="property-tags-link">trastero incluido</a>
                            </li>
                            <li className="property-tags-item">
                                <a href="#" className="property-tags-link">plaza garaje incluida</a>
                            </li>
                            <li className="property-tags-item">
                                <a href="#" className="property-tags-link">terraza</a>
                            </li>
                            <li className="property-tags-item">
                                <a href="#" className="property-tags-link">trastero incluido</a>
                            </li>
                            <li className="property-tags-item">
                                <a href="#" className="property-tags-link">adeje</a>
                            </li>
                            <li className="property-tags-item">
                                <a href="#" className="property-tags-link">obra nueva</a>
                            </li>
                        </ul>

                        <h3 className="property-description-title" data-aos="zoom-in" data-aos-duration="2000">Description</h3>
                        <div className="property-description-content" data-aos="fade-up" data-aos-duration="2000">
                            <p>REAL ESTATE BARRERAS TENERIFE PRESENTS: Last apartments available in the New Construction building located in Adeje, near the Galeón Shopping Center and all services needed: restaurants, bars, shops, supermarket, bus stop, taxi, school,
                                bank, medical center and etc. The project has been based on modern and minimalist ideas that stand out for their elegance in construction, creating a harmonious structure and a bright and welcoming atmosphere. The building has
                                an elevator. The apartment has 2 bedrooms with fitted wardrobes, a spacious living-dining room with an American kitchen, a bathroom and a terrace with sea views. The price includes a garage and storage room. The municipality of
                                Adeje is located in the Southwest of the Island and occupies an area of 105.94 km2, being the sixth largest municipality in Tenerife. In recent years, the municipality's coastline has undergone incredible development. Known as
                                Costa Adeje, it concentrates a hotel infrastructure of excellent quality, as well as a wide range of leisure, nature, rest and sports (with special mention to its golf course). Adeje also has 14 beaches with sand of all colours,
                                where it is possible to bathe throughout the year thanks to its average annual temperature of 24ºC and its almost 300 days of sunshine a year. Sur Reina Sofia airport is a 20-minute drive away and the beaches of Costa Adeje about
                                5-10 minutes.</p>
                        </div>    
                    </div>
                </div>
        {/* <!-- Property Details Section End --> */}
    </>
  )
}
