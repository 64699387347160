import React from 'react'

export default function BuyFilter() {
    let selectedOptionId = 0
  return (
    <section className="home-structure-section">
        <div className="container">
            <div className="home-structure-content">
                <div className="row">
                    <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                        <div className="home-structure-select">
                            <label className="form-label section-discription">Type</label>
                            <select className="form-select form-control"  defaultValue={selectedOptionId}>
                                <option value="0" hidden>All</option>
                                <option value="1">Flat</option>
                                <option value="2">House / Chalet</option>
                                <option value="3">Country House</option>
                                <option value="4">Hotel</option>
                                <option value="5">Parking</option>
                                <option value="6">Shop</option>
                                <option value="7">Industrial Ship</option>
                                <option value="8">Office</option>
                                <option value="9">Land</option>
                                <option value="10">Storage</option>
                                <option value="11">Building</option>
                            </select>
                        </div>
                    </div>
                    <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                        <div className="home-structure-select">
                            <label className="form-label section-discription">Sort by</label>
                            <select className="form-control form-select" defaultValue={selectedOptionId}>
                                <option value="0"  hidden>Recent</option>
                                <option>Highest price</option>
                                <option>Lowest price</option>
                            </select>
                        </div>
                    </div>
                    <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                        <div className="home-structure-select">
                            <label className="form-label section-discription">Bedrooms</label>
                            <select className="form-control form-select" defaultValue={selectedOptionId}>
                                <option value="0"  hidden>All</option>
                                <option value="1">1</option>
                                <option value="1">2</option>
                                <option value="1">3</option>
                                <option value="1">4</option>
                                <option value="1">5</option>
                                <option value="1">6</option>
                                <option value="1">7</option>
                                <option value="1">More than 7</option>
                            </select>
                        </div>
                    </div>
                    <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                        <div className="home-structure-select">
                            <label className="form-label section-discription">Bedrooms</label>
                            <select className="form-control form-select" defaultValue={selectedOptionId}>
                                <option value="0"  hidden>All</option>
                                <option value="1">1</option>
                                <option value="1">2</option>
                                <option value="1">3</option>
                                <option value="1">4</option>
                                <option value="1">5</option>
                                <option value="1">6</option>
                                <option value="1">7</option>
                                <option value="1">More than 7</option>
                            </select>
                        </div>
                    </div>
                    <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                        <div className="home-structure-select">
                            <label className="form-label section-discription">Tags</label>
                            <select className="form-control form-select" defaultValue={selectedOptionId}  >
                                <option value="0"  hidden>All</option>
                                <option value="1">Elevator</option>
                                <option value="1">Garage</option>
                                <option value="1">Garden</option>
                                <option value="1">Outdoor</option>
                                <option value="1">Rooftop terrace</option>
                                <option value="1">Ocean views</option>
                                <option value="1">Storage room</option>
                                <option value="1">Swimimng pool</option>
                                <option value="1">Terrace</option>
                            </select>
                        </div>
                    </div>
                    <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                        <div className="home-structure-select">
                            <label className="form-label section-discription" >Tags</label>
                            <input type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                        <div className="home-structure-select">
                            <label className="form-label section-discription" >Zone</label>
                            <select className="form-control form-select" defaultValue={selectedOptionId}>
                                <option value="0"  hidden>All</option>
                                <option value="1">Adeje</option>
                                <option value="1">Armeñime</option>
                                <option value="1">Callao Salvaje</option>
                                <option value="1">Costa Adeje</option>
                                <option value="1">El Duque</option>
                                <option value="1">La Caldera</option>
                                <option value="1">La Caleta</option>
                                <option value="1">Madroñal</option>
                                <option value="1">Playa de Las Americas</option>
                                <option value="1">Playa Paraiso</option>
                                <option value="1">Roque del Conde</option>
                                <option value="1">San Eugenio Alto</option>
                                <option value="1">San Eugenio Bajo</option>
                                <option value="1">Tijoco</option>
                                <option value="1">Torviscas Alto</option>
                                <option value="1">Torviscas Bajo</option>
                                <option value="1">Arona</option>
                                <option value="1">Buzanada</option>
                                <option value="1">Cabo Blanco</option>
                                <option value="1">Chayofa</option>
                                <option value="1">Costa del Silencio</option>
                                <option value="1">La Camella</option>
                                <option value="1">Las Galletas</option>
                                <option value="1">Los Cristianos</option>
                                <option value="1">Palm-Mar</option>
                                <option value="1">Parque de la Reina</option>
                                <option value="1">Valle San Lorenzo</option>
                                <option value="1">Granadilla de Abona</option>
                                <option value="1">San Isidro</option>
                                <option value="1">Los Abrigos</option>
                                <option value="1">La Tejita</option>
                                <option value="1">El Medano</option>
                                <option value="1">Guia de Isora</option>
                                <option value="1">Alcala</option>
                                <option value="1">Playa San Juan</option>
                                <option value="1">Norte</option>
                                <option value="1">San Cristobal de la Laguna</option>
                                <option value="1">Santa Cruz de Tenerife</option>
                                <option value="1">Puerto de la Cruz</option>
                                <option value="1">San Miguel de Abona</option>
                                <option value="1">Aldea Blanca</option>
                                <option value="1">Amarilla Golf</option>
                                <option value="1">El Roque</option>
                                <option value="1">Golf del Sur</option>
                                <option value="1">Guargacho</option>
                                <option value="1">Las Chafiras</option>
                                <option value="1">Las Zocas</option>
                                <option value="1">Llano del Camello</option>
                                <option value="1">Santiago del Teide</option>
                                <option value="1">Acantilados de Los Gigantes</option>
                                <option value="1">Arguayo</option>
                                <option value="1">Puerto de Santiago</option>
                            </select>
                        </div>
                    </div>
                    <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                        <div className="home-structure-range">
                            <div className="range-slider-container">
                                <input type="range" className="range-slider w-100" min="50.000,00" max="1000000" defaultValue="50.000,00" step="1" />
                            </div>

                            <p className="slider-counter-container">50.000,00 € <span className="slider-counter"></span></p>
                        </div>
                    </div>
                    <div className="col col-12 col-md-6 col-lg-4 col-xl-4">
                        <div className="home-structure-select mb-0">
                            <a href="/" className="btn btn-dark w-100 home-structure-serach-btn">Search</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
