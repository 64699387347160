import React from 'react'

export default function WhyBarreras() {
  return (
        <>
             <section className="why-barreras-section">
                <div className="container">
                    <h2 className="why-barreras-title" data-aos="zoom-in" data-aos-duration="1500">Why BARRERAS real estate?</h2>
                    <div className="row">
                        <div className="col col-12 col-md-8 mx-auto col-lg-6">
                            <div className="why-barreras-img" data-aos="fade-up" data-aos-duration="1500">
                                <img src="assets/images/why-barreras-img.png" alt="why-barreras-img" className="img-fluid"/>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-6">
                            <div className="why-barreras-content">
                                <p className="why-barreras-content-discription" data-aos="fade-up" data-aos-duration="1500">Real estate specialists committed to obtaining the best possible results for you. The real estate agency BARRERAS was founded in 2000 in Los Abrigos, a town located in the southern part of the island of Tenerife. The founder,
                                    Pablo Barreras, continued the family tradition that, for several generations, has been linked to the field of law and business.</p>
                                <div data-aos="fade-up" data-aos-duration="2000">
                                    <img src="assets/images/why-barreras.png" alt="why-barreras" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        </>
  )
}
