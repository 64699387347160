import React, { useEffect } from 'react'
import LandingSection from './LandingSection'
import BuyFilter from './BuyFilter'
import Property from './Property'
import Aos from 'aos'

export default function BuySellPage() {
  
  useEffect( () => {
    setTimeout(() => {
      Aos.init({
        once : true
      });
    }, 2000);
    Aos.refresh();
  });

  return (
    <>
        <LandingSection landingTitle="Properties for sale"></LandingSection>

        <BuyFilter></BuyFilter>
        <Property></Property>
    </>
  )
}
