import React from 'react'

export default function AboutUsFormate() {
  return (
            <>
             <section className="why-barreras-section about-us-section">
                    <div className="container">
                        <div className="about-us-section-item">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="why-barreras-content">
                                        <h3 className="why-barreras-about-us-title" data-aos="zoom-in" data-aos-duration="2000">About Us</h3>
                                        <p className="why-barreras-content-discription" data-aos="fade-up" data-aos-duration="2000">Currently, after the retirement of the founder of the agency, his students continue to lead it ahead, maintaining the principles and values ​​of its founder.<br/> We are members of the Association of Real Estate Agencies,
                                            which brings together the entire south of the island, and which allows cooperation between real estate professionals. This work philosophy is the present and the future of real estate brokerage in Tenerife: a system
                                            that brings together the widest real estate offer of the moment made available to the client so that he obtains the maximum advantages when buying or selling his home.</p>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="why-barreras-img" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src="assets/images/founder.png" alt="founder" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="about-us-section-item">
                                <div className="row">
                                    <div className="col col-12 col-md-6 col-lg-6">
                                        <div className="why-barreras-img" data-aos="zoom-in" data-aos-duration="2000">
                                            <img src="assets/images/target.png" alt="target" className="img-fluid"/>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-lg-6">
                                        <div className="why-barreras-content">
                                            <h3 className="why-barreras-about-us-title" data-aos="zoom-in" data-aos-duration="2000">Nuestra Misión</h3>
                                            <p className="why-barreras-content-discription" data-aos="fade-up" data-aos-duration="2000">Para brindar un servicio excepcional y los mejores resultados posibles para cada cliente con el que trabajamos.<br/> Our values ​​are rooted in the way our team operates, in the decisions we make and the actions we take.
                                                Our values ​​provide the foundation for our success.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about-us-section-item">
                                <div className="row">
                                    <div className="col col-12 col-md-6 col-lg-6">
                                        <div className="why-barreras-content">
                                            <h3 className="why-barreras-about-us-title" data-aos="zoom-in" data-aos-duration="2000">Our Services</h3>
                                            <p className="why-barreras-content-discription" data-aos="fade-up" data-aos-duration="2000">BARRERAS offers a full range of real estate services. We specialize in the sale of residential, tourist, commercial and rural properties, as well as property management services.<br/> We know that real estate clients want
                                                to deal with consultants who have knowledge, experience, honesty and integrity, and who are committed to achieving the best possible result for them.<br/> Above all, they want to deal with sensitive people, that is
                                                why we absolutely focus on creating a relationship of trust and respect with all our clients.</p>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-lg-6">
                                        <div className="why-barreras-img" data-aos="zoom-in" data-aos-duration="2000">
                                            <img src="assets/images/services.png" alt="services" className="img-fluid"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                         </div>
             </section>
            </>
  )
}
