import React from 'react'
import Slider from 'react-slick'

export default function RentPropertyAbout(props) {
  return (
        
        <>
             <section className="rent-slider-section mt-0">
                <div className="container ">
                    <div className="rent-slider-content">
                        <h2 className="rent-slider-title text-center section-main-title " data-aos="zoom-in" data-aos-duration="2000">Inmobiliaria Barreras
                        </h2>

                        {/* <div className="rent-slider-list" data-aos="fade-up" data-aos-duration="2000"> */}
                                <Slider {...props.setting}>           
                            <div className="rent-slider-item " data-aos="fade-up" data-aos-duration="2000">
                                <div className=" rent-slider-img ">
                                    <img src="assets/images/value-time-2.png" alt=" "/>
                                    <div className="rent-slider-img-two shadow">
                                        <img src="assets/images/value-time.png " alt=" "/>
                                    </div>
                                    <div className="rent-slider-details bg-white">
                                        <h2 className="rent-slider-details-title section-title">We Value Your Time</h2>
                                        <p className="rent-slider-details-discription section-discription">We know that your time is gold! We prioritize attention and dedication to the needs of each of our clients to capture their priorities and offer a taylored service focused on achieving their goal.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="rent-slider-item" data-aos="fade-up" data-aos-duration="2000">
                                <div className=" rent-slider-img ">
                                    <img src="assets/images/value-time-2.png" alt=" "/>
                                    <div className="rent-slider-img-two shadow">
                                        <img src="assets/images/value-time.png " alt=" "/>
                                    </div>
                                    <div className="rent-slider-details bg-white ">
                                        <h2 className="rent-slider-details-title section-title">We Value Your Time</h2>
                                        <p className="rent-slider-details-discription section-discription">We know that your time is gold! We prioritize attention and dedication to the needs of each of our clients to capture their priorities and offer a taylored service focused on achieving their goal.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="rent-slider-item" data-aos="fade-up" data-aos-duration="2000">
                                <div className="rent-slider-img ">
                                    <img src="assets/images/value-time-2.png" alt=" "/>
                                    <div className="rent-slider-img-two shadow">
                                        <img src="assets/images/value-time.png " alt=" "/>
                                    </div>
                                    <div className="rent-slider-details bg-white ">
                                        <h2 className="rent-slider-details-title section-title">We Value Your Time</h2>
                                        <p className="rent-slider-details-discription section-discription">We know that your time is gold! We prioritize attention and dedication to the needs of each of our clients to capture their priorities and offer a taylored service focused on achieving their goal.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="rent-slider-item" data-aos="fade-up" data-aos-duration="2000">
                                <div className="rent-slider-img ">
                                    <img src="assets/images/value-time-2.png" alt=" "/>
                                    <div className="rent-slider-img-two shadow">
                                        <img src="assets/images/value-time.png " alt=" "/>
                                    </div>
                                    <div className="rent-slider-details bg-white ">
                                        <h2 className="rent-slider-details-title section-title">We Value Your Time</h2>
                                        <p className="rent-slider-details-discription section-discription">We know that your time is gold! We prioritize attention and dedication to the needs of each of our clients to capture their priorities and offer a taylored service focused on achieving their goal.</p>
                                    </div>
                                </div>
                            </div>
                            </Slider>

                        {/* </div> */}
                    </div>
                </div>
        </section>
        </>
  )
}
