import React ,{useEffect} from 'react'
import LandingSection from '../buy_sell/LandingSection'
import Aos from 'aos';
import ServicesDescription from './ServicesDescription';
export default function Services() {
    useEffect( () => {
        setTimeout(() => {
          Aos.init({
            once : true
          });
        }, 2000);
        Aos.refresh();
      });
  return (  
            
            <>
                <LandingSection landingTitle="Services" background="service-landing-section"></LandingSection>
                <ServicesDescription></ServicesDescription>
            </>
        )
}
