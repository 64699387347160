import React from 'react'

export default function Team() {
  return (

        <>
         <section className="our-team-section">
            <div className="container">
                <h2 className="section-main-title text-center">Our Team</h2>
                <div className="row">
                    <div className="col col-12 col-md-4 col-lg-4">
                        <div className="our-team-card">
                            <div className="our-team-card-img" data-aos="fade-up" data-aos-duration="1000">
                                <img src="assets/images/team1.png" alt="team1" className="img-fluid"/>
                            </div>
                            <div className="our-team-card-body" data-aos="fade-up" data-aos-duration="1500">
                                <h3 className="our-team-member-name">Giambattista Guala</h3>
                                <a href="tel:+34638418917" className="our-team-member-number">+34 638 418 917</a>
                                <ul className="country-list">
                                    <li className="country-item">
                                        <img src="assets/images/country1.png" alt="country" className="img-fluid"/>
                                    </li>
                                    <li className="country-item">
                                        <img src="assets/images/country2.png" alt="country" className="img-fluid"/>
                                    </li>
                                    <li className="country-item">
                                        <img src="assets/images/country3.png" alt="country1" className="img-fluid"/>
                                    </li>
                                    <li className="country-item">
                                        <img src="assets/images/country4.png" alt="country1" className="img-fluid"/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col col-12 col-md-4 col-lg-4">
                        <div className="our-team-card">
                            <div className="our-team-card-img" data-aos="fade-up" data-aos-duration="1500">
                                <img src="assets/images/team2.png" alt="team2" className="img-fluid"/>
                            </div>
                            <div className="our-team-card-body" data-aos="fade-up" data-aos-duration="2000">
                                <h3 className="our-team-member-name">Giambattista Guala</h3>
                                <a href="tel:+34638418917" className="our-team-member-number">+34 638 418 917</a>
                                <ul className="country-list">
                                    <li className="country-item">
                                        <img src="assets/images/country1.png" alt="country" className="img-fluid"/>
                                    </li>
                                    <li className="country-item">
                                        <img src="assets/images/country2.png" alt="country" className="img-fluid"/>
                                    </li>
                                    <li className="country-item">
                                        <img src="assets/images/country3.png" alt="country1" className="img-fluid"/>
                                    </li>
                                    <li className="country-item">
                                        <img src="assets/images/country4.png" alt="country1" className="img-fluid"/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col col-12 col-md-4 col-lg-4">
                        <div className="our-team-card">
                            <div className="our-team-card-img" data-aos="fade-up" data-aos-duration="2000">
                                <img src="assets/images/team3.png" alt="team3" className="img-fluid"/>
                            </div>
                            <div className="our-team-card-body" data-aos="fade-up" data-aos-duration="2500">
                                <h3 className="our-team-member-name">Giambattista Guala</h3>
                                <a href="tel:+34638418917" className="our-team-member-number">+34 638 418 917</a>
                                <ul className="country-list">
                                    <li className="country-item">
                                        <img src="assets/images/country1.png" alt="country" className="img-fluid"/>
                                    </li>
                                    <li className="country-item">
                                        <img src="assets/images/country2.png" alt="country" className="img-fluid"/>
                                    </li>
                                    <li className="country-item">
                                        <img src="assets/images/country3.png" alt="country1" className="img-fluid"/>
                                    </li>
                                    <li className="country-item">
                                        <img src="assets/images/country4.png" alt="country1" className="img-fluid"/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
  )
}
