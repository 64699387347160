import React from 'react'

export default function ContactUsForm() {
  return (
        <>
            <div className="property-details-form">
                <form className="row g-3">
                    <div className="col-md-6" data-aos="fade-up" data-aos-duration="1000">
                        <label htmlFor="inputName" className="form-label">Name</label>
                        <input type="text" className="form-control" id="inputName" placeholder="Name"/>
                    </div>
                    <div className="col-md-6" data-aos="fade-up" data-aos-duration="1000">
                        <label htmlFor="inputEmail" className="form-label">Email</label>
                        <input type="email" className="form-control" id="inputEmail" placeholder="Email"/>
                    </div>
                    <div className="col-12" data-aos="fade-up" data-aos-duration="1000">
                        <label htmlFor="inputMessage" className="form-label">Message</label>
                        <textarea rows="3" className="form-control" id="inputMessage" placeholder="Message"></textarea>
                    </div>
                    <div className="col-12 text-center" data-aos="fade-up" data-aos-duration="1000">
                        <button type="submit" className="btn btn-dark">Send</button>
                    </div>
                </form>
        </div>
        </>
  )
}
