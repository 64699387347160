import React from 'react'

export default function InquerySectionMap() {
  return (
    <>
       {/* <!-- Inquery Section Map  --> */}
      <section className="contact-map-section" data-aos="fade-in" data-aos-duration="2500">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d552.0126180756639!2d-15.581918049465758!3d27.764790810363692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xc40958d8e4d7467%3A0x540b76846bc0357c!2sEkib%20Cycling%20Planet!5e0!3m2!1sen!2sin!4v1654853408928!5m2!1sen!2sin"
            style={{border:0}}  loading="lazy" referrerPolicy="no-referrer-when-downgrade">
       </iframe>
    </section>
    </>
    )
}
