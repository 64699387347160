import React, { useEffect } from 'react'

import HomeLanding from './homeLanding';
import BuyProperty from './buy_property';
import SellProperty from './sell_property';
import RentProperty from './rent_property';
import RentPropertySlider from './rent_property_slider';
import Partnerships from './partnerships';
import Testimonials from './testimonials';
import Aos from 'aos';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import setting from '../../general.js';


export default function HomePage() {



  // useEffect( () => {

  
  //   // var request = new XMLHttpRequest();

  //   // request.open('GET', 'https://witei.com/api/v1/houses');
    
  //   // request.setRequestHeader('Content-Type', 'application/json');
  //   // request.setRequestHeader('Authorization', 'Bearer { dd727fdf-14d9-455d-82bb-23cda2559959 }');
    
  //   // request.onreadystatechange = function () {
  //   //   if (this.readyState === 4) {
  //   //     console.log('Status:', this.status);
  //   //     console.log('Headers:', this.getAllResponseHeaders());
  //   //     console.log('Body:', this.responseText);
  //   //   }
  //   // };
    
  //   // request.send();
  // });

  useEffect( () => {
    setTimeout(() => {
      Aos.init({
        once : true
      });
    }, 2000);
    Aos.refresh();
  });
 
  return (
    <>
        <HomeLanding></HomeLanding>
        <BuyProperty></BuyProperty>
        <SellProperty></SellProperty>
        <RentProperty></RentProperty>
        <RentPropertySlider setting={setting()}></RentPropertySlider>
        <Partnerships></Partnerships>
        <Testimonials setting={setting()}></Testimonials>

    
    </>
  )
}

