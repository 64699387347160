import React from 'react'
import Slider from "react-slick";


export default function rent_property_slider(props) {
    


  return (
    <section className=" rent-slider-section">
    <div className="container">
        <div className=" rent-slider-content ">
            <h2 className="rent-slider-title text-center section-main-title "  data-aos="zoom-in" data-aos-duration="2000">THE LAND OF THE ETERNAL SPRING
            </h2>
            <Slider {...props.setting}>

            <div className="rent-slider-item" data-aos="zoom-in" data-aos-duration="2500">
                    <div className=" rent-slider-img ">
                        <img src="assets/images/rent_3.png " alt=" " className="img-fluid" />
                        <div className="rent-slider-img-two shadow">
                            <img src="assets/images/rent_4.png " alt=" " className="img-fluid" />
                        </div>
                        <div className="rent-slider-details bg-white ">
                            <h2 className="rent-slider-details-title section-title">RENT</h2>
                            <p className="rent-slider-details-discription section-discription">Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis minima dignissimos iusto doloribus est aliquid eaque officia tempore, ipsum dolorem, fuga in provident consectetur dicta nam nobis. Repellendus, est vitae?</p>
                            <a href="/" className="btn btn-dark ">View</a>
                        </div>
                    </div>
                </div>
                <div className="rent-slider-item ">
                    <div className=" rent-slider-img ">
                        <img src="assets/images/rent_3.png " alt=" " className="img-fluid" />
                        <div className="rent-slider-img-two shadow">
                            <img src="assets/images/rent_4.png " alt=" " className="img-fluid" />
                        </div>
                        <div className="rent-slider-details bg-white ">
                            <h2 className="rent-slider-details-title section-title">RENT</h2>
                            <p className="rent-slider-details-discription section-discription">Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis minima dignissimos iusto doloribus est aliquid eaque officia tempore, ipsum dolorem, fuga in provident consectetur dicta nam nobis. Repellendus, est vitae?</p>
                            <a href="/" className="btn btn-dark ">View</a>
                        </div>
                    </div>
                </div>
                <div className="rent-slider-item ">
                    <div className=" rent-slider-img ">
                        <img src="assets/images/rent_3.png " alt=" " className="img-fluid" />
                        <div className="rent-slider-img-two shadow">
                            <img src="assets/images/rent_4.png " alt=" " className="img-fluid" />
                        </div>
                        <div className="rent-slider-details bg-white ">
                            <h2 className="rent-slider-details-title section-title">RENT</h2>
                            <p className="rent-slider-details-discription section-discription">Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis minima dignissimos iusto doloribus est aliquid eaque officia tempore, ipsum dolorem, fuga in provident consectetur dicta nam nobis. Repellendus, est vitae?</p>
                            <a href="/" className="btn btn-dark ">View</a>
                        </div>
                    </div>
                </div>
                <div className="rent-slider-item ">
                    <div className=" rent-slider-img ">
                        <img src="assets/images/rent_3.png " alt=" " className="img-fluid" />
                        <div className="rent-slider-img-two shadow">
                            <img src="assets/images/rent_4.png " alt=" " className="img-fluid" />
                        </div>
                        <div className="rent-slider-details bg-white ">
                            <h2 className="rent-slider-details-title section-title">RENT</h2>
                            <p className="rent-slider-details-discription section-discription">Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis minima dignissimos iusto doloribus est aliquid eaque officia tempore, ipsum dolorem, fuga in provident consectetur dicta nam nobis. Repellendus, est vitae?</p>
                            <a href="/" className="btn btn-dark ">View</a>
                        </div>
                    </div>
                </div>
            </Slider>
            {/* <div className="rent-slider-list" data-aos="zoom-in" data-aos-duration="2500">
              
            </div> */}
        </div>
    </div>
</section>
  )
}
